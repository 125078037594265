<template>
  <div class="wrapper">
    <base-button
      icon type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{tagBtn: isActive}" />
    <div class="main-content" :class="{DashboardBody: isActive}">
        <SampleNavbar/>
        <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
            <b-breadcrumb>
                <b-breadcrumb-item href="#">Client</b-breadcrumb-item>
                <b-breadcrumb-item href="#" @click="$router.push({name: 'client-list'});">Client List</b-breadcrumb-item>
                <b-breadcrumb-item href="#" active>Client Documents</b-breadcrumb-item>
            </b-breadcrumb>
        </base-header>
        <b-container fluid class="mt--7">
            <b-card type="default" no-body>
              <pulse-loader v-if="loding" :color="color" :size="size" />
                <vue-good-table
                :columns="columns"
                :rows="rows"
                :line-numbers="true"
                :search-options="{
                enabled: true,
                placeholder: 'Search this table',
                }"
                :pagination-options="{
                enabled: true,
                mode: 'records'
                }"
                >
                  <template slot="table-row" slot-scope="props" style="w">
                    <span v-if="props.column.field == 'before'">
                        <img :src="props.row.client_document_data" width="10%" />
                    </span>
                    <span v-if="props.column.field == 'after'">
                        <base-button
                        icon type="primary"
                        size="sm"
                        @click="download(props.row.client_document_data)"
                        >
                        <span class="btn-inner--icon"><i class="ni ni-bold-down"></i></span>
                        </base-button>
                        <base-button
                        icon type="danger"
                        size="sm"
                        @click="documentDelete(props.row.client_document_id)"
                        >
                        <span class="btn-inner--icon">
                          <i class="ni ni-fat-remove"></i>
                        </span>
                        </base-button>
                    </span>
                    <span v-else>
                        {{props.formattedRow[props.column.field]}}
                    </span>
                  </template>
                </vue-good-table>
            </b-card>
        </b-container>
        <Footer/>
    </div>
  </div>
</template>
<script>
import Sidebar from './../../Starter/Sidebar'
import SampleNavbar from './../../Starter/SampleNavbar'
import Footer from './../../Starter/SampleFooter'
import { API_URL } from '../../../router/api'
export default {
  name: 'client-documents',
  components: {
    Sidebar,
    SampleNavbar,
    Footer
  },
  data () {
    return {
      color: '#5e72e4',
      size: '30px',
      margin: '2px',
      radius: '2px',
      loding: true,
      isActive: false,
      columns: [
        {
          label: 'Documents',
          field: 'before'
        },
        {
          label: 'Actions',
          field: 'after'
        }
      ],
      rows: []
    }
  },
  methods: {
    toggleClass: function (event) {
      this.isActive = !this.isActive
    },
    tableData() {
        let id = JSON.parse(this.$route.params.id);
        this.axios.get(API_URL+'admin_client_documents/'+id)
        .then((response) => {
        let obj = response.data ? response.data : []
        console.log(obj);
        this.rows = obj
        this.loding = false;
    })
    },
    download (data) {
        var a = document.createElement("a"); //Create <a>
        a.href = `${data}`; //Image Base64 Goes here
        a.download = "document.png"; //File name Here
        a.click(); //Downloaded file
    },
    documentDelete(id) {
        console.log(id);
        this.axios.post(API_URL+'admin_client_documents_delete/'+id)
        .then((res) => {
            let status = res.data.status;
            let message = res.data.message;
            if(status == true) {
                this.tableData();
                this.$swal.fire({
                  icon: 'success',
                  title: 'Oops...',
                  text: `${message}`,
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                })
            }else {
              this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${message}`,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              })
            }
        })
    }
  },
  mounted() {
    this.tableData();
  }
}
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
table.vgt-table {
    font-size: 12px;
}
</style>
